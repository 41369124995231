import { en } from 'vuetify/src/locale'
import { request } from '@/utils/request'

export default async () => ({
  $vuetify: {
    ...en
  },
  ...await request('/assets/translations/en.json'),

  attempts_left_total: {
    one: 'You have {count} out of {all} {unit} left to complete this task'
  },

  attempts_left_today: {
    one: 'You have {count} out of {all} {unit} left to complete this task today'
  },

  available: {
    one: 'Available'
  },

  points_count_of: {
    one: 'You scored {count} of {all} required points'
  },

  positive_score_of: {
    one: 'Passing score, not less than: {count} out of {all}'
  },

  $app: {
    loading: 'Loading...',
    expand_list_of_lessons: 'Expand the list of lessons',
    no_lessons: 'No lessons',
    exit: 'Exit',
    no_data: 'No data',
    upload_photo: 'Upload a photo',
    delete_photo: 'Delete photo',
    page_not_found: 'Page not found',
    nothing_selected: 'Nothing selected',

    menuItem: {
      groups: 'Groups',
      infobase: 'Infobase'
    },

    action: {
      one: 'Action'
    },

    ask_question: {
      one: 'Ask a question'
    },

    audio_recorder: {
      title: 'Audio recording',
      start: 'Start recording',
      stop: 'Stop recording',
      progress: 'Listening...',
      error: 'Could not find microphone'
    },

    chat: {
      delete_confirm: 'Are you sure you want to delete this chat?',
      select_title: 'Select a chat or'
    },

    chat_create: {
      title: 'New message',
      filesize_msg: 'You can attach one file to the message, the size is not more than {n} MB',
      action_title: 'Write a new message'
    },

    date_completion: {
      one: 'Date of completion'
    },

    go_management: 'Switch to order management mode',

    final_lesson: {
      one: 'Final (estimated) lesson'
    },

    lesson_status: {
      one: 'Lesson`s status'
    },

    lesson_type: {
      one: 'Lesson`s type'
    },

    message: {
      delete_confirm: 'Are you sure you want to delete this message?'
    },

    md_editor: {
      label: 'Answer (text in format <a href="https://en.wikipedia.org/wiki/Markdown" target="_blank" rel="noopener nofollow noreferrer">Markdown</a>):'
    },

    meeting: {
      action: 'Go to meeting'
    },

    next: {
      one: 'Next'
    },

    teacher_comments: "Teacher's comments",

    test_execution_time: 'This test must be completed during',

    essay_execution_time: 'This assignment must be completed during',

    documents_library: 'Documents library'
  }
})
